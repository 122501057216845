<template>
  <div class="mgAll10">
    <el-dialog class="custom-dialog" :title="'公司'+(formType?'编辑':'添加')" :visible.sync="dialogVisible" width="420px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="公司名称" prop="companyName" class="custom-form-item">
          <el-input v-model="form.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts" class="custom-form-item">
          <el-input v-model="form.contacts" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactNumber" class="custom-form-item">
          <el-input v-model="form.contactNumber" placeholder="请输入联系电话" type="number"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" class="custom-form-item">
          <el-input v-model="form.companyAddress" placeholder="请输入详细地址" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <div>
        <!-- 搜索 -->
        <el-input v-model="searchForm.companyName" placeholder="公司名称" class="mgB10 custom-form-input"></el-input>
        <el-select v-model="searchForm.enable" placeholder="状态" class="mgL10 mgB10 custom-form-input">
          <el-option value="" label="全部"></el-option>
          <el-option v-for="(item,index) in states" :key="index" :label="item.name" :value="item.value"></el-option>
        </el-select>
        <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
        </el-button>
        <el-button type="success" @click="add" plain class="mgL10 mgB10">添加
        </el-button>
        <!-- 表格 -->
        <el-table :data="tableData" border v-loading="loading" row-key="id">
          <el-table-column type="index" header-align="center" align="center" label="序号" width="50"></el-table-column>
          <el-table-column prop="companyName" :show-overflow-tooltip="true" header-align="center" align="center"
            label="公司名称"></el-table-column>
          <el-table-column prop="contacts" :show-overflow-tooltip="true" header-align="center" align="center"
            label="联系人"></el-table-column>
          <el-table-column prop="contactNumber" :show-overflow-tooltip="true" header-align="center" align="center"
            label="联系电话">
          </el-table-column>
          <el-table-column prop="enable" :show-overflow-tooltip="true" header-align="center" align="center" label="状态"
            width="120">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enable" @change="changeState(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="companyAddress" :show-overflow-tooltip="true" header-align="center" align="center"
            label="公司地址">
          </el-table-column>
          <el-table-column label="操作" width="120" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
                编辑
              </el-button>
              <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach">
        </table-page>
      </div>
    </el-card>
  </div>
</template>

<script>
  const state = [{
      value: 1,
      name: "启用"
    },
    {
      value: 0,
      name: "停用"
    }
  ]
  export default {
    name: "systemMenu",
    filters: {},
    data() {
      return {
        states: state,
        tableData: [],
        loading: false,
        searchForm: {
          companyName: "",
          enable: "",
          page: 1,
          limit: 10,
        },
        total: 0,
        dialogVisible: false,
        formType: false,
        loadingAlert: false,
        form: {
          companyName: "",
          contacts: "",
          contactNumber: "",
          companyAddress: ""
        },
        rules: {
          companyName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          contacts: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          contactNumber: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (!this.$util.isPoneAvailable(value)) {
                callback(new Error('请输入正确的手机号码!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }]
        }
      }
    },
    mounted() {
      this.search();
    },
    methods: {
      search() {
        this.getData(1, this.searchForm.limit)
      },
      getData(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.companyListPage, {
          companyName: this.searchForm.companyName,
          enable: this.searchForm.enable,
          page: page,
          limit: limit
        }).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      feach(e) {
        this.getData(e.page, e.limit)
      },
      changeState: function(e) {
        this.$axios.Post(this.$api.companyEnable, {
          "id": e.id,
          "enable": e.enable
        }).then(() => {}).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          e.enable = !e.enable
        })
      },
      editRow(row) {
        this.formType = true;
        this.dialogVisible = true;
        this.form = JSON.parse(JSON.stringify(row))
      },
      add() {
        this.dialogVisible = true;
      },
      //关闭
      closeAlert() {
        this.formType = false;
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.id = "";
        this.loadingAlert = false;
      },
      //表单提交
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信息
      addUser() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.companyAdd, this.form).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.companyModify, this.form).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //删除表格数据
      delRow: function(row) {
        this.$confirm('确认要删除该条信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.Post(this.$api.companyDel, {
            "id": row.id
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getData(this.searchForm.page, this.searchForm.limit)
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      }
    },
    components: {
      "table-page": () => import('@/components/TablePage')
    }
  }
</script>

<style lang="less">
  .custom-card+.custom-card {
    margin-top: 10px;
  }
</style>
